import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "./footer";
import Header from "./header/header";

type Props = {
  title?: string;
  description?: string;
  ogType?: string;
  ogImage?: string;
  textColor?: "black" | "white";
};

const Layout: React.FC<Props> = ({
  title,
  description,
  ogType,
  ogImage,
  children,
  textColor,
}) => {
  const { site, allWpNews } = useStaticQuery(query);
  const {
    defaultTitle,
    siteUrl,
    defaultDescriptionJP,
    defaultDescriptionEN,
    defaultImage,
  } = site.siteMetadata;
  const newsCount = allWpNews.totalCount;

  const lang = process.env.GATSBY_LANG === "EN" ? "en" : "ja";
  title = title ? `${title} | ${defaultTitle}` : defaultTitle;
  description =
    description ||
    (process.env.GATSBY_LANG === "JP"
      ? defaultDescriptionJP
      : process.env.GATSBY_LANG === "EN"
      ? defaultDescriptionEN
      : "");

  const { pathname } = useLocation();
  const url = siteUrl + pathname;
  // image = siteUrl + (image || defaultImage);
  ogImage = ogImage ?? siteUrl + defaultImage;

  if (ogType == null) ogType = "article";
  const prefix = ["og: https://ogp.me/ns#"];
  prefix.push(`${ogType}: https://ogp.me/ns/${ogType}#`);

  return (
    <>
      <Helmet>
        <html lang={lang} prefix={prefix.join(" ")} />
        <title>{title}</title>

        <link rel="canonical" href={url} />
        {/* meta */}
        <meta name="description" content={description} />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,viewport-fit=cover"
        />
        <meta
          name="format-detection"
          content="telephone=no, email=no, address=no"
        />
        {/* OGP */}
        <meta property="og:type" content={ogType} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={defaultTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <Header newsCount={newsCount} textColor={textColor} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;

const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle: title
        siteUrl
        defaultDescriptionJP: descriptionJP
        defaultDescriptionEN: descriptionEN
        defaultImage: ogImage
      }
    }
    allWpNews {
      totalCount
    }
  }
`;
