/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Link } from "gatsby";
import * as React from "react";
import MediaQuery from "react-responsive";
import Burger from "./Burger";
import HeaderLogo from "./header-logo";
import LanguageSwitch from "./LanguageSwitch";
import Overlay from "./overlay-menu";
import { useLocation } from "@reach/router";

type DataProps = {
  newsCount?: number;
  textColor?: "white" | "black";
};

const Header = ({ newsCount, textColor = "black" }: DataProps): JSX.Element => {
  const location = useLocation();
  const m = location.pathname.match(/\/(.+)\/.*/);
  // console.log(m);

  const [overlay, setOverlay] = React.useState(false);

  const toggleOverlay = () => {
    setOverlay(!overlay);
  };
  const closeOverlay = () => {
    setOverlay(false);
  };

  return (
    <header
      css={css`
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 50;
        height: 60px;
        @media (min-width: 767px) {
          height: 80px;
        }
        @media (min-width: 1299px) {
          height: 100px;
        }
      `}
    >
      <div
        style={{
          padding: "16px 20px",
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <div style={{ flex: 1 }}>
          <HeaderLogo color={textColor} />
        </div>

        <MediaQuery maxWidth={767}>
          <Burger color={textColor} onClick={toggleOverlay} />
          {overlay && <Overlay onClose={closeOverlay} />}
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <div style={{ flex: 1, display: "flex", gap: "25px" }}>
            <span>
              <Link
                to="/work/"
                css={css`
                  color: ${textColor};
                  font-weight: 700;
                  text-decoration-thickness: 2px;
                  text-decoration-line: ${m && (m[1] === "work"||m[1] === "work/detail")
                    ? "underline"
                    : "none"};
                  transition: color 1.5s;
                  &:hover {
                    text-decoration-line: underline;
                  }
                `}
              >
                PROJECT
              </Link>
            </span>
            <span>
              <Link
                to="/about/"
                css={css`
                  color: ${textColor};
                  font-weight: 700;
                  text-decoration-thickness: 2px;
                  text-decoration-line: ${m && m[1] === "about"
                    ? "underline"
                    : "none"};
                  transition: color 1.5s;
                  &:hover {
                    text-decoration-line: underline;
                  }
                `}
              >
                ABOUT
              </Link>
            </span>
            {(newsCount ?? 0) > 0 && (
              <span>
                <Link
                  to="/news/"
                  css={css`
                    color: ${textColor};
                    font-weight: 700;
                    text-decoration-thickness: 2px;
                    text-decoration-line: ${m && m[1] === "news"
                      ? "underline"
                      : "none"};
                    transition: color 1.5s;
                    &:hover {
                      text-decoration-line: underline;
                    }
                  `}
                >
                  NEWS
                </Link>
              </span>
            )}
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>
              {/* <Link
                to="/lab/"
                css={css`
                  color: #ffe500;
                  font-weight: 700;
                  text-decoration-thickness: 2px;
                  text-decoration-line: none;
                  &:hover {
                    text-decoration-line: underline;
                  }
                `}
              >
                FormLAB
              </Link> */}
              <a
                target="_blank"
                href="https://formtokyo.com"
                css={css`
                  color: ${textColor};
                  font-weight: 700;
                  text-decoration-thickness: 2px;
                  text-decoration-line: none;
                  &:hover {
                    text-decoration-line: underline;
                  }
                `}
              >
                Form
              </a>
            </span>
            <LanguageSwitch color={textColor} />
          </div>
        </MediaQuery>
      </div>
    </header>
  );
};

export default Header;
