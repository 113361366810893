/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { navigate } from "gatsby";

type DataProps = {
  color?: string;
};

const HeaderLogo = ({ color = "black" }: DataProps): JSX.Element => {
  const linkTo = () => {
    navigate("/");
  };

  return (
    <div>
      <h1 style={{ margin: 0 }}>
        <svg
          css={css`
            width: 200px;
            height: auto;
            @media (max-width: 1299px) {
              width: 158px;
            }
            @media (max-width: 767px) {
              width: 86px;
            }
            cursor: pointer;
          `}
          width="206"
          height="59"
          viewBox="0 0 206 59"
          xmlns="http://www.w3.org/2000/svg"
          onClick={linkTo}
        >
          <g>
            <path
              fill={color}
              style={{ transition: "fill 1.5s linear" }}
              d="M33.9475 21.7723H52.293V23.7443C50.0281 24.3431 47.9458 25.4914 46.2307 27.0874C44.4996 28.686 42.7198 31.701 40.8915 36.1326C39.3488 39.7892 37.9351 42.4753 36.6503 44.1909C39.7074 48.5581 42.1067 51.3284 43.8481 52.5019C45.4845 53.6421 47.4298 54.2561 49.4243 54.2618C52.1089 54.2618 54.1798 52.99 55.637 50.4463H57.6089C57.0475 52.7369 55.6855 54.7512 53.769 56.1255C51.8027 57.5719 49.416 58.333 46.9754 58.2918C41.4052 58.2918 36.5044 55.4908 32.2731 49.8888C27.2447 55.4927 21.9301 58.2937 16.3291 58.2918C13.5831 58.292 10.8804 57.6067 8.46633 56.2979C5.98302 54.9884 3.87887 53.0613 2.35667 50.7023C0.762489 48.2265 -0.0584011 45.3321 -0.00136798 42.388C-0.00136798 38.7905 0.978121 35.769 2.9371 33.3234C4.89042 30.8826 8.64092 27.8033 14.1886 24.0856C10.8433 19.4279 9.16977 15.2114 9.16794 11.4363C9.16794 8.46996 10.3129 5.82045 12.6027 3.48776C14.8856 1.16433 17.7566 0.00162897 21.2158 -0.000332148C24.2078 -0.0313995 27.1333 0.882667 29.5754 2.61161C32.0609 4.35628 33.3044 6.64219 33.3059 9.46935C33.3059 13.8703 29.191 18.1426 20.9611 22.286C26.0417 29.1726 30.8422 35.8304 35.3627 42.2596C36.9409 40.0672 38.2356 37.6841 39.2161 35.1669C40.1599 32.7227 40.6333 30.8144 40.6363 29.4421C40.6363 26.1555 38.4067 24.2562 33.9475 23.7443L33.9475 21.7723ZM15.4703 25.8421C12.7331 27.4534 10.3786 29.6399 8.56937 32.2505C6.9422 34.6947 6.12931 37.0591 6.1307 39.3439C6.1307 42.8586 7.54429 46.1803 10.3715 49.3091C13.2018 52.4385 16.403 54.0021 19.9751 53.9999C23.9477 53.9999 27.6193 52.0445 30.99 48.1339C24.989 38.9578 19.8157 31.5272 15.4703 25.8421V25.8421ZM19.7631 20.6588C25.4714 17.314 28.3269 13.6971 28.3295 9.80815C28.3295 7.49743 27.6944 5.62661 26.424 4.19568C25.7949 3.48974 25.0175 2.93176 24.1473 2.56177C23.2772 2.19178 22.3359 2.01895 21.3911 2.05568C19.3287 2.05568 17.6773 2.74117 16.4368 4.11213C15.1812 5.50789 14.5125 7.3343 14.57 9.21087C14.57 12.0125 16.301 15.8284 19.7631 20.6588Z"
            />
            <path
              fill={color}
              style={{ transition: "fill 1.5s linear" }}
              d="M69.2005 5.94665V21.7727H87.9938V26.819H69.2005V57.3636H63.6987V0.899384H88.5919V5.94665H69.2005Z"
            />
            <path
              fill={color}
              style={{ transition: "fill 1.5s linear" }}
              d="M93.1213 39.8402C93.1212 36.1817 94.2059 32.6054 96.2383 29.5634C98.2707 26.5214 101.16 24.1504 104.539 22.7502C107.919 21.35 111.639 20.9835 115.227 21.6971C118.815 22.4107 122.111 24.1722 124.698 26.7591C127.285 29.3459 129.047 32.6417 129.761 36.2299C130.475 39.818 130.109 43.5373 128.709 46.9173C127.309 50.2974 124.938 53.1864 121.896 55.2191C118.854 57.2517 115.278 58.3368 111.62 58.3369C109.19 58.3395 106.783 57.8629 104.538 56.9342C102.293 56.0056 100.252 54.6433 98.5342 52.9252C96.816 51.2071 95.4535 49.1671 94.5247 46.9218C93.5959 44.6765 93.119 42.27 93.1213 39.8402ZM98.2438 39.765C98.2438 47.4593 103.692 53.7113 111.62 53.7113C119.555 53.7113 125.004 47.4593 125.004 39.765C125.004 32.1536 119.555 25.8239 111.62 25.8239C103.692 25.8239 98.2438 32.1536 98.2438 39.765V39.765Z"
            />
            <path
              fill={color}
              style={{ transition: "fill 1.5s linear" }}
              d="M142.399 25.9827H142.55C144.646 23.2157 146.446 21.1922 150.338 21.1922C152.364 21.1922 154.004 21.8664 155.73 22.8389L152.891 27.2998C151.867 26.495 150.592 26.0786 149.291 26.1246C142.697 26.1246 142.399 35.3843 142.399 39.9534V57.3636H137.207V21.7727H142.399V25.9827Z"
            />
            <path
              fill={color}
              style={{ transition: "fill 1.5s linear" }}
              d="M166.65 25.9709H166.802C168.674 23.045 171.675 21.1779 175.264 21.1779C179.386 21.1779 182.335 23.3476 184.203 27.0176C186.298 23.1219 191.288 21.1779 195.557 21.1779C204.544 21.1779 206 28.3229 206 35.7383V57.3636H200.802V36.7091C200.802 32.1434 200.505 26.1246 194.363 26.1246C187.471 26.1246 185.93 32.7931 185.93 38.1099V57.3636H180.735V37.1628C180.735 32.8169 180.207 26.1246 174.438 26.1246C167.406 26.1246 166.65 33.2427 166.65 38.5611V57.3636H161.454V21.7727H166.65V25.9709Z"
            />
          </g>
        </svg>
      </h1>
    </div>
  );
};

export default HeaderLogo;
