import * as React from "react";
import { Link } from "gatsby";
import LanguageSwitch from "./LanguageSwitch";
import HeaderLogo from "./header-logo";
import CloseBtn from "../close-btn";
import { useLocation } from "@reach/router";

type DataProps = {
  onClose?: () => void;
};

const OverlayMenu = ({ onClose = () => {} }: DataProps): JSX.Element => {
  const location = useLocation();
  const m = location.pathname.match(/\/(.+)\/.*/);
  // console.log(m);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          margin: "16px 20px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <HeaderLogo color={"black"} />
          <CloseBtn color="black" onClick={onClose} />
        </div>

        <div style={{}}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.0rem",
              fontSize: "3.0rem",
              marginTop: "2.0rem",
            }}
          >
            <span>
              <Link
                to="/work/"
                style={{
                  textDecoration: m && m[1] === "work" ? "underline" : "none",
                  color: "#000",
                  textDecorationThickness: "2px",
                }}
              >
                PROJECT
              </Link>
            </span>
            <span>
              <Link
                to="/about/"
                style={{
                  textDecoration: m && m[1] === "about" ? "underline" : "none",
                  color: "#000",
                  textDecorationThickness: "2px",
                }}
              >
                ABOUT
              </Link>
            </span>
            <span>
              <Link
                to="/news/"
                style={{
                  textDecoration: m && m[1] === "news" ? "underline" : "none",
                  color: "#000",
                  textDecorationThickness: "2px",
                }}
              >
                NEWS
              </Link>
            </span>
            <span>&nbsp;</span>
            <span>
              {/* <Link
                to="/lab/"
                style={{
                  textDecoration: "none",
                  color: "#ffe500",
                }}
              >
                FormLAB
              </Link> */}
              <a
                target="_blank"
                href="https://formtokyo.com"
                style={{
                  textDecoration: m && m[1] === "about" ? "underline" : "none",
                  color: "#000",
                  textDecorationThickness: "2px",
                }}
              >
                Form
              </a>
            </span>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            bottom: "16px",
            left: "20px",
          }}
        >
          <LanguageSwitch fontSize="2.5rem" fontWeight={400} />
        </div>
      </div>
    </div>
  );
};

export default OverlayMenu;
