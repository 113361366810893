import * as React from "react";

const burgerStyle: React.CSSProperties = {
  display: "block",
  cursor: "pointer",
  border: "none",
  backgroundColor: "transparent",
  margin: "auto",
  padding: 0,
};
const burgerInnerStyle: React.CSSProperties = {
  width: "1.5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "6px",
};

type DataProps = {
  color?: string;
  onClick?: () => void;
};

const Burger = ({
  color = "black",
  onClick = () => {},
}: DataProps): JSX.Element => {
  const style: React.CSSProperties = {
    borderBottomWidth: "2px",
    borderBottomStyle: "solid",
    borderBottomColor: color,
    transition: "border-bottom-color 1.5s",
  };

  return (
    <div>
      <button style={burgerStyle} onClick={onClick}>
        <div style={burgerInnerStyle}>
          <div style={style}></div>
          <div style={style}></div>
          <div style={style}></div>
        </div>
      </button>
    </div>
  );
};

export default Burger;
